import React, { PureComponent } from 'react';
import { Grid } from '@material-ui/core';
import CategoriesList from './CategoriesList';
import ProductList from './ProductsList';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { menuOpen } from '../../../redux/actions';

class Content extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			dataCategories: [],
			actualCategory: {
				subcategories: [],
				title: ''
			},
			dataProducts: []
		};
	}

	componentDidMount() {
		this.mounted = true;
		this.getCategories();
	}

	async getCategories() {
		if (this.mounted) {
			const defaultSubcategory = {
				actualCategory: {
					subcategories: [],
					title: ''
				}
			};
			//saber si tiene productos la subcategoría
			let dataProducts = [];
			if (this.props.categories[0]) {
				if (this.props.categories[0].subcategories[0]) {
					if (this.props.categories[0].subcategories[0].products) {
						dataProducts = this.props.categories[0].subcategories[0].products;
					}
				}
			}
			this.setState({
				dataCategories:this.props.categories,
				actualCategory: this.props.categories[0] ? this.props.categories[0] : defaultSubcategory,
				dataProducts
			});
		}
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	render() {
		const rootStyle = {
			width: '76vw',
			height: '60vh'
		};

		//manejar click en una categoría
		const handleOnClickCategory = ({ idCategory }) => {
			this.props.history.push(`/subcategories/${idCategory}`);
			this.props.menuOpen(false);
		};

		const handleOnEnterCategory = ({ idCategory }) => {
			console.log(this.state.actualCategory);
			
			const category = this.state.dataCategories.find((category) => category.idCategory === idCategory);
			if (category) {
				this.setState({
					actualCategory: category,
					dataProducts: category.subcategories[0] ? category.subcategories[0].products : []
				});
			}
		};
		//manejar click en subcategoría
		const handleOnClickUpdateSubcategory = ({ idCategory, idSubcategory, idProduct = 0 }) => {
			this.props.history.push(`/product/${idCategory}/${idSubcategory}/${idProduct}`);
			this.props.menuOpen(false);
		};
		//manejar onMouseEnter en subcategoría
		const handleOnEnterUpdateSubcategory = (subCategory) => {
			const { idSubcategory } = subCategory;
			//mostrar los productos  de esa subcategoria
			console.log(this.state.actualCategory)
			const products = this.state.actualCategory.subcategories.find(
				(subCategory) => subCategory.idSubcategory === idSubcategory
			).products;
			console.log(products);
			
			this.setState({ dataProducts: products ? products : [] });
		};

		return (
			<Grid
				container
				direction="row"
				justify="space-between"
				alignItems="flex-start"
				style={rootStyle}
				onMouseLeave={() => {
					this.props.menuOpen(false);
				}}
			>
				<Grid item xs={3}>
					<CategoriesList
						categories={this.state.dataCategories}
						handleOnClick={handleOnClickCategory}
						title={'CATEGORÍA'}
						handleOnEnter={handleOnEnterCategory}
					/>
				</Grid>
				<Grid item xs={3}>
					<CategoriesList
						categories={this.state.actualCategory.subcategories}
						handleOnClick={handleOnClickUpdateSubcategory}
						title={this.state.actualCategory.title}
						handleOnEnter={handleOnEnterUpdateSubcategory}
					/>
				</Grid>
				<Grid item xs={6}>
					<ProductList products={this.state.dataProducts} />
				</Grid>
			</Grid>
		);
	}
}

function mapToProps(state){
	return {categories:state.categories}
}

export default connect(mapToProps, { menuOpen })(withRouter(Content));
