import { combineReducers } from "redux";
import categories from './categories'
import user from './user'
import menu from "./menu";
import cart from './cart'
import modal from './modal'
import promos from './promos'
import kiosk from './kiosk'

const reducer = combineReducers({
    user,
    cart,
    categories,
    modal,
    promos,
    kiosk,
    menu
})

export default reducer