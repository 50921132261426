/* shape
    idProduct: 0,
	image: 'http://localhost:3001/products/defaultp.jpg',
	nameProduct: '6 fotos infantiles',
	priceYouPrint: 34.5,
	quantity: 1
*/

const initialState = {
	isOpen: false,
	products: []
};

const categoryReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'OPEN_CART':
			return { ...state, isOpen: true };
		case 'CLOSE_CART':
			return { ...state, isOpen: false };
		case 'TOGGLE_CART':
			return { ...state, isOpen: !state.isOpen };
		case 'INSERT_CART_PRODUCT':
			const preState = state;
			preState.products.unshift(action.payload);
			return preState;
		case 'SET_CART_PRODUCT':
			console.log(action.payload);
			
			return { ...state, products: action.payload };
		case 'DELETE_CART_PRODUCT':
			const products = state.products.filter((cart) => cart.idShop !== action.payload);
			return { ...state, products };
		case 'UPDATE_PRODUCT_QUANTITY':
			console.log(action.payload);
			
			const { idShop,quantity:quantityShop } = action.payload.product;
			const updated = state.products.map(
				(cart) => {
					if (cart.idShop === idShop) {
						cart={...cart,quantityShop}
					}
					return cart
				}
			);
			return { ...state, products: updated };
		default:
			return state;
	}
};

export default categoryReducer;
