const initialState = {
    loginOpen: false,
}

const menuReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'OPEN_LOGIN_MODAL':
            return {...state,loginOpen:true}
        case 'CLOSE_LOGIN_MODAL':
            return { ...state, loginOpen:false }
        default:
            return state
    }

}

export default menuReducer