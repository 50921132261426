import { Popover } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { menuOpen } from '../../../redux/actions';
import Content from './Content';

class PopoverMenu extends Component {
	render() {
		return (
			<div>
				<Popover
					open={this.props.open}
					anchorEl={this.props.anchorEl}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left'
					}}
					onClose={this.props.handlePopoverClose}
				>
					<Content />
				</Popover>
			</div>
		);
	}
}

export default connect(state => state.menu, { menuOpen })(PopoverMenu);
