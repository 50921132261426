/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { logIn } from '../../../redux/actions';
import { logIn as logInService } from '../../../services/login';
import Copyright from '../Copyright';
import { validateEmail, validatePassword } from '../../../services/validations';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { LinearProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	paper: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	links: {
		cursor: 'pointer',
	},
}));

export default function LogIn({
	handleNoUserAccount,
	handleForgottenPassword,
	handleOnClose,
}) {
	const classes = useStyles();
	const [password, setPassword] = useState('');
	const [validPassword, setValidPassword] = useState(true);
	const [email, setEmail] = useState('');
	const [validEmail, setValidEmail] = useState(true);
	const [valid, setValid] = useState(true);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const { executeRecaptcha } = useGoogleReCaptcha();

	const handleOnKeyEnter=e=>{
		if (e.key === "Enter") {
			handleOnSubmit()
		}
	}

	const handleOnChangeEmail = (e) => {
		setEmail(e.target.value);
		setValidEmail(validateEmail(e.target.value));
	};
	const handleOnChangePassword = (e) => {
		setPassword(e.target.value);
		setValidPassword(validatePassword(e.target.value));
	};

	const handleOnSubmit = async () => {
		//validar campos
		setValidEmail(validateEmail(email));
		setValidPassword(validatePassword(password));
		setValid(validEmail && validPassword);
		if (!(validateEmail(email) && validatePassword(password))) {
			return;
		}
		//iniciar cargando
		setLoading(true);
		//llamar al ws
		const token = await executeRecaptcha('login');
		const success = await logInService(email, password, token);
		setLoading(false);

		if (success) {
			dispatch(logIn(success.token));
			handleOnClose();
			return;
		}
		setValid(false);
	};

	return (
		<div className={classes.paper}>
			<Avatar className={classes.avatar}>
				<LockOutlinedIcon />
			</Avatar>
			<Typography component="h1" variant="h5">
				Login
			</Typography>
			{loading ? (
				<div className={classes.form}>
					<LinearProgress />
				</div>
			) : (
				<form className={classes.form}>
					<TextField
						value={email}
						error={!validEmail}
						variant="standard"
						margin="normal"
						required
						helperText={
							validEmail
								? null
								: 'favor de introducir un correo válido'
						}
						fullWidth
						id="email"
						label="Correlo electrónico"
						name="email"
						autoComplete="email"
						autoFocus
						onChange={handleOnChangeEmail}
					/>
					<TextField
						value={password}
						error={!validPassword}
						variant="standard"
						margin="normal"
						required
						helperText={
							validPassword
								? null
								: `la contraseña debe tener una longitud mínima de 8 caracteres;
								al menos un número, una letra minúscula, 
								una letra mayúscula y un símbolo`
						}
						fullWidth
						name="password"
						label="Contraseña"
						type="password"
						onChange={handleOnChangePassword}
						onKeyPress={handleOnKeyEnter}
						id="password"
						autoComplete="current-password"
					/>
					{!valid ? (
						<Typography color="error">
							Favor de validar los campos
						</Typography>
					) : null}
					<Button
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
						onClick={handleOnSubmit}
					>
						Iniciar sesión
					</Button>
					<Grid container>
						<Grid item xs>
							<Link
								onClick={handleForgottenPassword}
								className={classes.links}
								variant="body2"
							>
								¿Olvidaste tu contraseña?
							</Link>
						</Grid>
						<Grid item>
							<Link
								onClick={handleNoUserAccount}
								className={classes.links}
								variant="body2"
							>
								Aún no tengo cuenta
							</Link>
						</Grid>
					</Grid>
					<Box mt={5}>
						<Copyright />
					</Box>
				</form>
			)}
		</div>
	);
}
