const initialState = []

const promoReducer = (state = initialState, action) => {
	const promos = action.payload;
	switch (action.type) {
		case 'SET_PROMOS':
			return promos;
		default:
			return state;
	}
};

export default promoReducer;
