/* eslint-disable react/prop-types */
import React, { useState, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Avatar from '@material-ui/core/Avatar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { logIn } from '../../../redux/actions';
import { signIn as signInService } from '../../../services/login';
import { validateEmail, validatePassword } from '../../../services/validations';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { LinearProgress } from '@material-ui/core';
import { Link as LinkRouter } from 'react-router-dom';
import Copyright from '../Copyright';
import ValidatedSignUp from './ValidatedSignUp';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
	paper: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	links: {
		cursor: 'pointer',
	},
}));

export default function LogIn({
	handleUserAccount,
	handleOnClose,
	handleForgottenPassword,
}) {
	const classes = useStyles();
	const [password, setPassword] = useState('');
	const [passwordCopy, setPasswordCopy] = useState('');
	const [email, setEmail] = useState('');
	const [valid, setValid] = useState(true);
	const [validPasswordCopy, setValidPasswordCopy] = useState(true);
	const [validPassword, setValidPassword] = useState(true);
	const [validEmail, setValidEmail] = useState(true);
	const [termsAndCond, setTermsAndCond] = useState(false);
	const { executeRecaptcha } = useGoogleReCaptcha();
	const [loading, setLoading] = useState(false);
	const [finished, setFinished] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const dispatch = useDispatch();

	const handleOnKeyEnter = (e) => {
		if (e.key === 'Enter') {
			handleOnSubmit();
		}
	};

	const handleOnChangeEmail = (e) => {
		setEmail(e.target.value);
		setValidEmail(validateEmail(e.target.value));
	};
	const handleOnChangePassword = (e) => {
		setPassword(e.target.value);
		setValidPassword(validatePassword(e.target.value));
	};

	const handleOnChangePasswordCopy = (e) => {
		setPasswordCopy(e.target.value);
		setValidPasswordCopy(password === e.target.value);
	};

	const handleOnCheckTerms = (e) => {
		setTermsAndCond(e.target.checked);
	};

	const handleOnSubmit = async () => {
		//validar campos
		setValidEmail(validateEmail(email));
		setValidPassword(validatePassword(password));
		setValidPasswordCopy(
			validatePassword(passwordCopy) && passwordCopy === password
		);

		setValid(
			validateEmail(email) &&
				validatePassword(password) &&
				validatePassword(passwordCopy) &&
				passwordCopy === password
		);
		if (
			!(
				validateEmail(email) &&
				validatePassword(password) &&
				validatePassword(passwordCopy) &&
				passwordCopy === password
			)
		) {
			return;
		}

		if (!termsAndCond) {
			setValid(false);
			return;
		}

		//google recaptcha
		setLoading(true);
		const token = await executeRecaptcha('signin');
		const response = await signInService(
			email,
			password,
			passwordCopy,
			token
		);
		setLoading(false);
		if (!response) {
			return setValid(false);
		}
		if (response.status === 203) {
			setValid(false);
			return enqueueSnackbar('Usuario ya registrado', {
				variant: 'warning',
			});
		}
		if (response.status !== 200) {
			setValid(false);
			return enqueueSnackbar('Error al registrar', { variant: 'error' });
		}
		setFinished(true)
		sessionStorage.setItem('token', response.token);
		dispatch(logIn());
		return;
	};

	return (
		<div className={classes.paper}>
			<Avatar className={classes.avatar}>
				<LockOutlinedIcon />
			</Avatar>
			<Typography component="h1" variant="h5">
				Registro
			</Typography>
			{loading ? (
				<div className={classes.form}>
					<LinearProgress />
				</div>
			) : (
				<div>
					{finished ? (
						<ValidatedSignUp handleOnClose={handleOnClose}/>
					) : (
						<form className={classes.form}>
							<TextField
								value={email}
								variant="standard"
								margin="normal"
								required
								fullWidth
								id="email"
								label="Correlo electrónico"
								name="email"
								autoComplete="email"
								autoFocus
								onChange={handleOnChangeEmail}
								error={!validEmail}
								helperText={
									validEmail
										? null
										: 'Favor de introducir un correo válido'
								}
							/>
							<TextField
								value={password}
								variant="standard"
								margin="normal"
								required
								fullWidth
								name="password"
								label="Contraseña"
								type="password"
								onChange={handleOnChangePassword}
								id="password"
								autoComplete="current-password"
								error={!validPassword}
								helperText={
									validPassword
										? null
										: `la contraseña debe tener una longitud mínima de 8 caracteres;
							al menos un número, una letra minúscula, 
							una letra mayúscula y un símbolo`
								}
							/>
							<TextField
								value={passwordCopy}
								variant="standard"
								margin="normal"
								required
								fullWidth
								name="password"
								label="Repita la contraseña"
								type="password"
								onChange={handleOnChangePasswordCopy}
								id="password"
								onKeyPress={handleOnKeyEnter}
								autoComplete="current-password"
								error={!validPasswordCopy}
								helperText={
									validPasswordCopy
										? null
										: 'Las contraseñas deben coincidir'
								}
							/>
							<FormControlLabel
								control={
									<Checkbox
										value="remember"
										color="primary"
										checked={termsAndCond}
										onChange={handleOnCheckTerms}
									/>
								}
								label={
									<Fragment>
										Acepto los
										<LinkRouter
											to={'/terminos'}
											onClick={handleOnClose}
										>
											<Typography
												color="primary"
												display="inline"
											>
												{' términos y condiciones'}
											</Typography>
										</LinkRouter>
									</Fragment>
								}
							/>
							{!valid ? (
								<Typography color="error">
									Favor de validar los campos
								</Typography>
							) : null}
							<Button
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
								onClick={handleOnSubmit}
							>
								Iniciar sesión
							</Button>
							<Grid container>
								<Grid item xs>
									<Link
										onClick={handleForgottenPassword}
										className={classes.links}
										variant="body2"
									>
										¿Olvidaste tu contraseña?
									</Link>
								</Grid>
								<Grid item>
									<Link
										onClick={handleUserAccount}
										className={classes.links}
										variant="body2"
									>
										{'Ya tengo cuenta'}
									</Link>
								</Grid>
							</Grid>
							<Box mt={5}>
								<Copyright />
							</Box>
						</form>
					)}
				</div>
			)}
		</div>
	);
}
