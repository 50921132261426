import { uniqueId } from "lodash";

export const b64toBlob = (dataURI) => {
	const BASE64_MARKER = ';base64,';
	// Format of a base64-encoded URL:
	// data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAYAAAAEOCAIAAAAPH1dAAAAK
	const mime = dataURI.split(BASE64_MARKER)[0].split(':')[1];
	const filename =`dataURI-file-${uniqueId()}-${new Date().getTime()}.${mime.split('/')[1]}`
	const bytes = atob(dataURI.split(BASE64_MARKER)[1]);
	const writer = new Uint8Array(new ArrayBuffer(bytes.length));

	for (var i = 0; i < bytes.length; i++) {
		writer[i] = bytes.charCodeAt(i);
	}

	return new File([writer.buffer], filename, { type: mime });
};

export async function getIpAdress() {
	try {
		const response = await fetch(`https://api.ipify.org?format=json`);
		if (!response.ok) {
			throw Error(response.statusText);
		}
		const json = await response.json();
		return json.ip;
	} catch (error) {
		console.error(error);
		return false;
	}
}

/**
 * Permite saber si el producto es valido para la promoción que tiene
 * @param {Object} product
 */
export const productHavePromo = (product) => {
	const { idOffer, quantityShop, quantityOffer } = product;
	return idOffer && quantityShop % quantityOffer === 0;
};
