//info de cards
import { SERVER_BASE_URL } from '../constants';

export const getPromotions = async function() {
	try {
		const response = await fetch(`${SERVER_BASE_URL}promotions`,{
			headers: {
				'Content-Type': 'application/json',
				'token':sessionStorage.getItem('token'),
			}
		});
		if (!response.ok) {
			throw Error(response.statusText);
		} else {
			const json = await response.json();
			return json.values;
		}
	} catch (error) {
		console.log(error);
		return [];
	}
};
