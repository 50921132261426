import React, { Fragment } from 'react';
import { Paper, Grid, Typography, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

const CartProduct = ({
	product: {
		price,
		name,
		quantityShop: quantity,
		image,
		idProduct,
		idCategory,
		idSubcategory,
		idShop,
		idOffer,
		priceWithDiscount,
		quantityOffer,
	},
	handleOnDelete,
	onClick,
}) => {
	const style = {
		img: {
			width: '85%',
			borderRadius: '5%',
			margin: '5%',
		},
		root: {
			margin: 10,
		},
	};

	return (
		<div>
			<Link
				to={`/product/${idCategory || 0}/${idSubcategory || 0}/${
					idProduct || 0
				}`}
			>
				<Paper style={style.root} onClick={onClick}>
					<Grid container direction="row">
						<Grid item xs={4}>
							<img src={image} style={style.img} alt={name} />
						</Grid>
						<Grid item xs={6}>
							<Grid
								container
								direction="column"
								justify="flex-start"
								alignItems="flex-start"
							>
								<Grid item xs={12}>
									<Typography variant="body1" display="block">
										Producto:
									</Typography>
									<Typography
										variant="caption"
										display="block"
									>
										{` ${name}`}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography
										variant="body1"
										display="inline"
									>
										Cantidad:
									</Typography>
									<Typography
										variant="caption"
										display="inline"
									>
										{` ${quantity}`}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									{idOffer &&
									quantity % quantityOffer === 0 ? (
										<Fragment>
											<Typography
												variant="body1"
												display="inline"
											>
												Oferta:
											</Typography>
											<Typography
												variant="caption"
												display="inline"
												color="secondary"
											>
												{` ${parseFloat(
													priceWithDiscount
												).toFixed(2)}`}
											</Typography>
										</Fragment>
									) : (
										<Fragment>
											<Typography
												variant="body1"
												display="inline"
											>
												Precio:
											</Typography>
											<Typography
												variant="caption"
												display="inline"
											>
												{` ${parseFloat(price).toFixed(
													2
												)}`}
											</Typography>
										</Fragment>
									)}
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={2}>
							<IconButton
								color="primary"
								aria-label="upload picture"
								component="span"
								onClick={handleOnDelete(idShop)}
							>
								<HighlightOffRoundedIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Paper>
			</Link>
		</div>
	);
};

export default CartProduct;
