import React, { Fragment, useState } from 'react';
import { Grid } from '@material-ui/core';
import Navbar from './components/Navbar';
import Container from './components/Container';
import {
	BrowserRouter as Router,
	useHistory,
	useLocation,
} from 'react-router-dom';
import { logIn, logOut, logOutKiosk, logInKiosk, setCartProduct } from './redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import 'swiper/css/swiper.css';
import Footer from './components/Footer';
import LoginWindow from './components/LoginWindow';
import { PROTECTED_ROUTES } from './constants';
import { validateSession } from './services/login';
import Loading from './components/miscellaneous/Loading';

async function keepSession(onValidated, onReject) {
	const token = sessionStorage.getItem('token');

	if (!token) return onReject();
	try {
		const validated = await validateSession(token);
		if (!validated) return onReject();
		console.log({ validated });

		return onValidated();
	} catch (error) {
		console.log(error);
		onReject();
	}
}

const isProtected = (path) => {
	return PROTECTED_ROUTES.find((protectedRoute) =>
		path.match(protectedRoute)
	);
};

function App() {
	const [isLoading, setIsLoading] = useState(true);
	const dispatch = useDispatch();
	const isLogged = useSelector((state) => state.kiosk.isLogged);
	const history = useHistory();
	const location = useLocation();

	//validación de kiosco
	const kioskToken = sessionStorage.getItem('tokenKiosk');
	const rememberMe = sessionStorage.getItem('keepSession');

	React.useEffect(() => {
		const onValidated = () => dispatch(logIn());

		const onReject = () => {
			dispatch(logOut());
			dispatch(setCartProduct([]));
			sessionStorage.removeItem('token');
			if (isProtected(location.pathname)) {
				history.push('/');
			}
		};
		validateSession(kioskToken).then((validated) => {
			setIsLoading(false);
			if (!validated) {
				return dispatch(logOutKiosk());
			}
			if (rememberMe === 'true') {
				return dispatch(logInKiosk(kioskToken));
			}
			if (isLogged) {
				return dispatch(logInKiosk(kioskToken));
			}
			return dispatch(logOutKiosk());
		});
		keepSession(onValidated, onReject);
	}, [dispatch, rememberMe, kioskToken, history, location, isLogged]);

	const logged = (
		<Fragment>
			{/* Navbar de arriba  :0*/}
			<Grid item xs={12}>
				<Navbar />
			</Grid>
			{/* container c: */}
			<Grid item xs={12}>
				<Container />
			</Grid>
			<Grid item xs={12}>
				<Footer />
			</Grid>
		</Fragment>
	);

	return (
		<Router>
			<div className="App">
				<Grid container direction="row" justify="center">
					{isLoading ? (
						<Loading />
					) : (
						<>{isLogged ? logged : <LoginWindow />}</>
					)}
				</Grid>
			</div>
		</Router>
	);
}

export default App;
