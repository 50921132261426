import React, { useState, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { recoverPasswordEmail } from '../../../services/login';
import Copyright from '../Copyright';
import { validateEmail } from '../../../services/validations';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { LinearProgress } from '@material-ui/core';

const PasswordForgotten = ({
	handleOnClose,
	handleNoUserAccount,
	handleUserAccount
}) => {
	const useStyles = makeStyles(theme => ({
		paper: {
			margin: theme.spacing(8, 4),
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center'
		},
		avatar: {
			margin: theme.spacing(1),
			backgroundColor: theme.palette.secondary.main
		},
		form: {
			width: '100%',
			marginTop: theme.spacing(1)
		},
		submit: {
			margin: theme.spacing(3, 0, 2)
		},
		links: {
			cursor: 'pointer'
		}
	}));

	const classes = useStyles();
	const [email, setEmail] = useState('');
	const [validEmail, setValidEmail] = useState(true);
	const [valid, setValid] = useState(true);
	const [loading, setLoading] = useState(false);
	const [nextSteps, setNextSteps] = useState(false);
	const { executeRecaptcha } = useGoogleReCaptcha();

	const handleOnKeyEnter=e=>{
		if (e.key === "Enter") {
			handleOnSubmit()
		}
	}

	const handleOnChangeEmail = e => {
		setEmail(e.target.value);
		setValidEmail(validateEmail(e.target.value));
	};

	const handleOnSubmit = async () => {
		//validar campos
		setValidEmail(validateEmail(email));
		setValid(validEmail);
		if (!validateEmail(email)) {
			return;
		}
		//iniciar cargando
		setLoading(true);
		//llamar al ws
		const token = await executeRecaptcha('recovery');
		const success = await recoverPasswordEmail(email, token);
		setLoading(false);

		if (success) {
            setNextSteps(true)
			return;
		}
		setValid(false);
	};

	return (
		<div className={classes.paper}>
			<Avatar className={classes.avatar}>
				<LockOutlinedIcon />
			</Avatar>
			<Typography component="h1" variant="h5">
				Contraseña olvidada
			</Typography>
			{loading ? (
				<div className={classes.form}>
					<LinearProgress />
				</div>
			) : (
				<div>
					{nextSteps ? (
						<Fragment>
							<Typography variant="body2">
								De estár registrado se enviará un correo con los
								pasos a seguir para restaurar tu contraseña.
							</Typography>
							<Button
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
								onClick={handleOnClose}
							>
								Cerrar
							</Button>
						</Fragment>
					) : (
						<form className={classes.form}>
							<TextField
								value={email}
								error={!validEmail}
								variant="standard"
								margin="normal"
								required
								helperText={
									validEmail
										? null
										: 'favor de introducir un correo válido'
								}
								fullWidth
								id="email"
								label="Correlo electrónico"
								name="email"
								autoComplete="email"
								autoFocus
								onKeyPress={handleOnKeyEnter}
								onChange={handleOnChangeEmail}
							/>
							{!valid ? (
								<Typography color="error">
									Favor de validar los campos
								</Typography>
							) : null}
							<Button
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
								onClick={handleOnSubmit}
							>
								Recuperar contraseña
							</Button>
							<Grid container>
								<Grid item xs>
									<Link
										onClick={handleUserAccount}
										className={classes.links}
										variant="body2"
									>
										Ya tengo cuenta
									</Link>
								</Grid>
								<Grid item>
									<Link
										onClick={handleNoUserAccount}
										className={classes.links}
										variant="body2"
									>
										Aún no tengo cuenta
									</Link>
								</Grid>
							</Grid>
							<Box mt={5}>
								<Copyright />
							</Box>
						</form>
					)}
				</div>
			)}
		</div>
	);
};

export default PasswordForgotten;
