export const open = (idCategory) => {
	return {
		type: 'OPEN',
		payload: { idCategory },
	};
};

export const setPromos = (promos) => {
	console.log('promos', promos);

	return {
		type: 'SET_PROMOS',
		payload: promos ? promos : [],
	};
};

export const menuOption = (option) => {
	return {
		type: 'MENU_OPTION',
		payload: option,
	};
};

export const menuOpen = (open) => {
	return {
		type: 'MENU_OPEN',
		payload: open,
	};
};

export const logIn = (token) => {
	return { type: 'SIGN_IN', payload: token };
};

export const logInKiosk = (token) => {
	return { type: 'SIGN_IN_KIOSK', payload: token };
};


export const logOut = () => {
	return { type: 'LOG_OUT' };
};

export const logOutKiosk = () => {
	return { type: 'LOG_OUT_KIOSK' };
};
export const openCart = () => {
	return { type: 'OPEN_CART' };
};

export const closeCart = () => {
	return { type: 'CLOSE_CART' };
};

export const toggleCart = () => {
	return { type: 'TOGGLE_CART' };
};
export const insertCartProduct = (product) => {
	return { type: 'INSERT_CART_PRODUCT', payload: product };
};
/**
 * Define el carrito de compras
 * @param {Array} product productos que definen el carrito de compras
 */
export const setCartProduct = (product) => {
	return { type: 'SET_CART_PRODUCT', payload: product };
};
export const deleteCartProduct = (idShop) => {
	return { type: 'DELETE_CART_PRODUCT', payload: idShop };
};

export const updateCartProductQuantity = (product) => {
	return { type: 'UPDATE_PRODUCT_QUANTITY', payload: { product } };
};

export const setCategories = (categories) => {
	return { type: 'SET_CATEGORIES', payload: categories };
};

export const openLoginModal = (open) => {
	return { type: 'OPEN_LOGIN_MODAL' };
};

export const closeLoginModal = (open) => {
	return { type: 'CLOSE_LOGIN_MODAL' };
};
