import React, {Component} from 'react';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import { Drawer } from "@material-ui/core";
import ListSidebar from './ListSidebar';
import { menuOpen } from '../../../redux/actions'
import { connect } from "react-redux";
import logo from "../logoTecnicamara-w.png";
import { Link } from "react-router-dom";

const useStyles = theme => ({
    root: {
        maxWidth: 256,
    },
    imageLogo: {
        margin: "auto",
        marginTop: 10,
        marginBottom: 10,
        width: '100%',
    },
    backgroundGradient:{
        background:theme.palette.primary.main,
    }
});

class SideBar extends Component {

    render() {
        const toggleDrawer = openBar => event => {
            if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }
            this.props.menuOpen(openBar)
        };

        return (
            <Drawer open={this.props.open} onClose={toggleDrawer(false)}>
                <Grid container className={this.props.classes.root}>
                    <Grid item xs={12} className={this.props.classes.backgroundGradient}>
                        <Link to='/' onClick={toggleDrawer(false)}>
                            <img alt="Logo YouPrint" src={logo} className={this.props.classes.imageLogo} />
                        </Link>
                    </Grid>
                    <Grid item xs={12}>
                        <ListSidebar />
                    </Grid>
                </Grid>
            </Drawer>
        );
    }
}

const styled = connect(state => state.menu, { menuOpen})(SideBar);

export default withStyles(useStyles)(styled)