import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import ProductCard from '../../../miscellaneous/ProductCard';
import { Link } from 'react-router-dom';
import { menuOpen } from '../../../../redux/actions';
import { useDispatch } from 'react-redux';
const useStyles = makeStyles((theme) => ({
	container: {
		overflow: 'auto',
		height: '53.5vh'
	},
	gridList: {
		overflow: 'auto',
		height: '53.5vh'
	}
}));

function ProductList({ products }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const handleOnClick = (e) => {
		dispatch(menuOpen(false));
	};

	const items = products.map((product) => (
		<Grid item xs={6} lg={4} onClick={handleOnClick} key={product.idProduct}>
			<Link to={`/product/${product.idCategory || 0}/${product.idSubcategory || 0}/${product.idProduct || 0}`}>
				<ProductCard card={product} />
			</Link>
		</Grid>
	));
	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography color={'secondary'} variant={'h5'}>
					PRODUCTOS
				</Typography>
			</Grid>
			<Grid container className={classes.container}>
				{items}
			</Grid>
		</Grid>
	);
}

export default ProductList;
