//info de cards
import { SERVER_BASE_URL } from '../constants';
export const getProductsImportant = async function () {
	try {
		const response = await fetch(`${SERVER_BASE_URL}productsImportant`, {
			headers: {
				'Content-Type': 'application/json',
				token: sessionStorage.getItem('token'),
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		} else {
			const json = await response.json();
			json.products.map(
				(product) => (product.image = SERVER_BASE_URL + product.image)
			);
			return json;
		}
	} catch (error) {
		console.log(error);
		return [];
	}
};

export const productDescBySubcategory = async function (
	idCategory,
	idSubcategory
) {
	try {
		const response = await fetch(
			`${SERVER_BASE_URL}productDescBySubcategory`,
			{
				method: 'POST',
				body: JSON.stringify({ idCategory, idSubcategory }),
				headers: {
					'Content-Type': 'application/json',
					token: sessionStorage.getItem('token'),
				},
			}
		);
		if (!response.ok) {
			throw Error(response.statusText);
		} else {
			const json = await response.json();
			json.products.map((product) => {
				product.image = SERVER_BASE_URL + product.image;
				const { featuresProduct } = product;
				product.featuresProduct = JSON.parse(featuresProduct);
				return product;
			});
			return json;
		}
	} catch (error) {
		console.log(error);
		return [];
	}
};

export const productsByName = async function (search) {
	try {
		const response = await fetch(`${SERVER_BASE_URL}productsByName`, {
			method: 'POST',
			body: JSON.stringify({ search }),
			headers: {
				'Content-Type': 'application/json',
				token: sessionStorage.getItem('token'),
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		} else {
			const json = await response.json();
			return json;
		}
	} catch (error) {
		console.log(error);
		return [];
	}
};

export const getProductByID = async function (id) {
	try {
		const response = await fetch(`${SERVER_BASE_URL}productsById`, {
			method: 'POST',
			body: JSON.stringify({ id }),
			headers: {
				'Content-Type': 'application/json',
				token: sessionStorage.getItem('token'),
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		} else {
			const json = await response.json();
			const products = json.products.map(
				(product) => {
					product.image = `${SERVER_BASE_URL}${product.image}`
					return product
				}
			);
			return {products};
		}
	} catch (error) {
		console.log(error);
		return [];
	}
};
