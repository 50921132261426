import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
	Badge,
	Grid,
	Hidden,
	IconButton,
	ListItemIcon,
	Menu,
	Tooltip,
	MenuItem,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
	toggleCart,
	openLoginModal,
	closeLoginModal,
	logOut,
	setCartProduct,
} from '../../redux/actions';
import Cart from './PopoverCart';
import LogIn from './LoginDialog';
import MoreVertRounded from '@material-ui/icons/MoreVertRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import ShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCartRounded';
import InputRoundedIcon from '@material-ui/icons/InputRounded';

export default function NavIcons() {
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorCartEl, setAnchorCartEl] = useState(null);
	const [open, setOpen] = useState(false);

	let cartButton = React.createRef();
	//handlers
	const toggleMenuIcons = (event) => {
		setAnchorEl(event.currentTarget);
		setOpen(!open);
	};

	const handleCloseLogin = () => dispatch(closeLoginModal());
	const handleOnLogOut=() => {
		sessionStorage.removeItem('token')
		dispatch(setCartProduct([]))
		dispatch(logOut())
	}
	
	const handleOpenLogin = () => {
		dispatch(openLoginModal());
	};

	const handleClose = () => setOpen(false);

	const handleOpenCart = () => dispatch(toggleCart());

	//busquedas en redux
	const cartCount = useSelector((state) => state.cart.products.length || 0);
	const isLogged = useSelector((state) => state.user.isLogged);

	//iconos, cambian dependiendo de si esta logueado o no
	const UserDesktop = () =>
		isLogged ? (
			<>
			<Tooltip title={<span style={{ fontSize: 18 }}>Salir</span>}>
				<IconButton color={'inherit'} onClick={handleOnLogOut}>
					<PersonRoundedIcon />
				</IconButton>
			</Tooltip>
			</>
		) : (
			<Tooltip title={<span style={{ fontSize: 18 }}>Ingresa</span>}>
				<IconButton color={'inherit'} onClick={handleOpenLogin}>
					<InputRoundedIcon />
				</IconButton>
			</Tooltip>
		);

	const UserMobile = () =>
		isLogged ? (
			<MenuItem onClick={handleClose}>
				<Link to={'/user'}>
					<ListItemIcon>
						<PersonRoundedIcon color={'inherit'} />
					</ListItemIcon>
					Perfil
				</Link>
			</MenuItem>
		) : (
			<MenuItem
				onClick={() => {
					handleOpenLogin();
					handleClose();
				}}
			>
				<ListItemIcon onClick={handleOpenLogin}>
					<InputRoundedIcon color={'inherit'} />
				</ListItemIcon>
				Ingresa
			</MenuItem>
		);

	useEffect(() => {
		setAnchorCartEl(cartButton.current);
	}, [cartButton]);
	return (
		<Fragment>
			<LogIn handleClose={handleCloseLogin} />
			<Hidden xsDown>
				<Grid item container xs={3} lg={2} justify={'space-evenly'}>
					<Grid item>
						<UserDesktop />
					</Grid>
					<Grid item>
						<Tooltip
							title={
								<span style={{ fontSize: 18 }}>Carrito</span>
							}
						>
							<IconButton
								color={'inherit'}
								onClick={handleOpenCart}
								ref={cartButton}
							>
								<Badge
									color="secondary"
									badgeContent={cartCount}
								>
									<ShoppingCartRoundedIcon />
								</Badge>
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
				<Cart anchorEl={anchorCartEl} />
			</Hidden>
			<Hidden smUp>
				<Grid item xs={2}>
					<IconButton
						aria-label="more"
						aria-controls="long-menu"
						aria-haspopup="true"
						color="inherit"
						onClick={toggleMenuIcons}
					>
						<MoreVertRounded />
					</IconButton>
					<Menu
						anchorEl={anchorEl}
						keepMounted
						open={open}
						onClose={handleClose}
						PaperProps={{
							style: {
								width: 200,
							},
						}}
					>
						{UserMobile()}
						<MenuItem onClick={handleClose}>
							<Link to={'/cart'}>
								<ListItemIcon>
									<Badge
										color="secondary"
										badgeContent={cartCount}
									>
										<ShoppingCartRoundedIcon
											color={'inherit'}
										/>
									</Badge>
								</ListItemIcon>
								Carrito
							</Link>
						</MenuItem>
					</Menu>
				</Grid>
			</Hidden>
		</Fragment>
	);
}
