import React, { lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCategories, setPromos, setCartProduct } from '../../redux/actions';
import getDataCategories from '../../services/categories';
import { getPromotions } from '../../services/promos';
import { getCart } from '../../services/cart';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Switch, Route } from 'react-router-dom';
import Loading from '../miscellaneous/Loading'
const Home = lazy(() => import('./Home'));
const Product = lazy(() => import('./Product'));
const Cart = lazy(() => import('./Cart'));
const Category = lazy(() => import('./Category'));
const TermsAndCond = lazy(() => import('./TermsAndCond'));
const Subcategory = lazy(() => import('./Subcategory'));
const ImageEditor = lazy(() => import('./Product/ImageEditor'));
const Checkout = lazy(() => import('./Cart/Checkout'));
const Recovery = lazy(() => import('./Recovery'));
const Billing = lazy(() => import('./Billing'));

const useStyles = makeStyles(() => ({
	root: {
		minHeight: '60vh',
		paddingLeft: 0,
		paddingRight: 0,
	},
}));

const Container = () => {
	const dispatch = useDispatch();
	const { isLogged } = useSelector((state) => {
		const { isLogged } = state.user;
		return {
			isLogged,
		};
	});

	React.useEffect(() => {
		if (!isLogged) {
			getDataCategories().then((data) => dispatch(setCategories(data)));
			getPromotions().then((promos) => dispatch(setPromos(promos)));
		} else {
			getDataCategories().then((data) => dispatch(setCategories(data)));
			getCart().then((data) => dispatch(setCartProduct(data)));
		}
	}, [isLogged, dispatch]);

	const classes = useStyles();
	return (
		<Grid container direction="row" className={classes.root}>
			<Suspense fallback={<Loading/>}>
				<Switch>
					<Route exact path="/" component={Home} />
					<Route exact path="/cart" component={Cart} />
					<Route exact path="/checkout" component={Checkout} />
					<Route exact path="/categories" component={Category} />
					<Route exact path="/terminos" component={TermsAndCond} />
					<Route exact path="/recover" component={Recovery} />
					<Route exact path="/billing" component={Billing} />
					<Route
						exact
						path="/subcategories/:idCategory(\d+)"
						component={Subcategory}
					/>
					<Route
						exact
						path="/product/:idCategory(\d+)/:idSubcategory(\d+)/:idProduct(\d+)/:quantity([01][0-9][0-9]|[1-9][0-9]|[1-9]|200)/editor"
						component={ImageEditor}
					/>
					<Route
						exact
						path="/product/:idCategory(\d+)/:idSubcategory(\d+)/:idProduct(\d+)/:quantity(\d*)?"
						component={Product}
					/>
					<Route>404 :c Not Found</Route>
				</Switch>
			</Suspense>
		</Grid>
	);
};

export default Container;
