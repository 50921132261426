import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

const ProductCardContent = ({ content }) => {
	const useStyles = makeStyles({
		root: {
			height: '100%'
		},
		h4Text: {
			fontSize: 'calc(0.8em + 0.6vw)'
		},
		h5Text: {
			fontSize: 'calc(0.8em + 0.4vw)'
		},
		h6Text: {
			fontSize: 'calc(0.8em + 0.15vw)'
		}
	});

	const classes = useStyles();
	return (
		<Grid container direction="row" className={classes.root}>
			<Grid
				container
				item
				xs={12}
				direction="row"
				alignItems="flex-start"
			>
				<Grid item xs={12}>
					<Typography
						color={'secondary'}
						display={'block'}
						className={classes.h4Text}
						noWrap
					>
						{` ${content.title}`}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography
						color={'secondary'}
						display={'inline'}
						className={classes.h5Text}
					>
						{`Desde:`}
					</Typography>
					<Typography
						color={'primary'}
						display={'inline'}
						className={classes.h4Text}
					>
						{` $${parseFloat(content.price).toFixed(2)}`}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ProductCardContent;
