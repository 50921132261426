//info de cards
import { SERVER_BASE_URL, SERVER_BASE_URL_SHOP } from '../constants';
import { b64toBlob } from '../helpers';

export const getCart = async function () {
	try {
		const response = await fetch(`${SERVER_BASE_URL_SHOP}Shopget`, {
			headers: {
				token: sessionStorage.getItem('token'),
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		}
		const json = await response.json();
		json.shop.map(
			(product) => (product.image = `${SERVER_BASE_URL}${product.image}`)
		);
		return json.shop;
		
	} catch (error) {
		console.log(error);
		return [];
	}
};

/**
 *
 * @param {number} idCategory id de la categoría
 * @param {number} idSubcategory id de la subcategoría
 * @param {number} idProduct id del producto
 * @param {number} quantity cantidad de producto
 */
export const insertCart = async function (
	idProduct,
	quantityShop,
	promoId = null,
	images = []
) {
	const formData = new FormData();
	formData.append('idProduct', idProduct);
	formData.append('quantityShop', quantityShop);
	if (promoId) {
		formData.append('promoId', promoId);
	}
	for (const image of images) {
		const blob = b64toBlob(image);
		formData.append('images', blob);
	}

	try {
		const response = await fetch(`${SERVER_BASE_URL_SHOP}ShopInsert`, {
			method: 'POST',
			body: formData,
			headers: {
				// 'Content-Type':'multipart/form-data',
				token: sessionStorage.getItem('token'),
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		}
		const json = await response.json();
		return json;
	} catch (error) {
		console.log(error);
		return [];
	}
};

export const deleteCart = async function (idShop) {
	try {
		const response = await fetch(`${SERVER_BASE_URL_SHOP}ShopDelete`, {
			method: 'POST',
			body: JSON.stringify({ idShop }),
			headers: {
				'Content-Type': 'application/json',
				token: sessionStorage.getItem('token'),
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		}
		return true;
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const updateCart = async function (
	idShop,
	quantityShop,
	idProduct,
	idOffer
) {
	console.log(idOffer);
	
	try {
		const response = await fetch(`${SERVER_BASE_URL_SHOP}ShopUpdate`, {
			method: 'POST',
			body: JSON.stringify({ idShop, quantityShop, idProduct, idOffer }),
			headers: {
				'Content-Type': 'application/json',
				token: sessionStorage.getItem('token'),
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		}
		return true;
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const saleInsert = async function (
	name,
	user,
	idPaymentMethod,
	lastCardNumbers
) {
	const payload = {
		name,
		street: user.streetClient,
		suburb: user.suburbClient,
		zipcode: user.zipcodeClient,
		extNumber: user.extNumberClient,
		intNumber: user.intNumberClient,
		telephone: user.telephoneClient,
		idCity: user.idCity,
		idKiosk: user.selectKiosk ? user.idKiosk : 0,
		idPay: idPaymentMethod,
		numCard: lastCardNumbers,
	};

	try {
		const response = await fetch(`${SERVER_BASE_URL_SHOP}saleInsert`, {
			method: 'POST',
			body: JSON.stringify(payload),
			headers: {
				'Content-Type': 'application/json',
				token: sessionStorage.getItem('token'),
			},
		});
		if (!response.ok || response.status !== 200) {
			throw Error(response.statusText);
		}
		const data = await response.json();
		console.log(data);
		console.log(data.idSelling);

		return data.idSelling;
	} catch (error) {
		console.log(error);
		return false;
	}
};
