import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { SERVER_BASE_URL } from "../../../constants";
import { ListItem } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        paddingTop: '30%',
        borderRadius: 5,
        marginBottom: 3,
        '&:hover': {
            zIndex: 1,
            '& $imageBackdrop': {
                opacity: 0.0,
            },
        },
    },
    imageTitle: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        color: theme.palette.common.white,
        align: "center",
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    imageSrc: {
        borderRadius: 5,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
        borderRadius: 5,
        position: 'absolute',
        width: '100%',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
        transition: theme.transitions.create('opacity'),
    },
}));

export default function SimpleList({ option, handleOnClick ,handleOnEnter=()=>{return}}) {
    const classes = useStyles();

    return (
        <ListItem button aria-haspopup="true">
            <ButtonBase
                focusRipple
                className={classes.root}
                onClick={e => handleOnClick(option)}
                onMouseEnter={e => { handleOnEnter(option)}}
            >
                <span
                    className={classes.imageSrc}
                    style={{
                        backgroundImage: `url(${encodeURI(SERVER_BASE_URL+option.image)})`,
                    }}
                />
                <span className={classes.imageBackdrop} >

                </span>
                <Typography
                    display={'block'}
                    variant="overline"
                    className={classes.imageTitle}
                >
                    {option.title}
                </Typography>
            </ButtonBase>
        </ListItem>

    );
}