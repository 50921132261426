import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { MuiThemeProvider } from '@material-ui/core';
import theme from './theme';
import { createStore } from 'redux';
import reducer from './redux/reducer';
import { Provider } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { RECAPTCHA_KEY } from './constants';
import SnackbarProvider from './components/SnackbarProvider';
import { BrowserRouter } from 'react-router-dom';

const store = createStore(
	reducer,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

ReactDOM.render(
	<MuiThemeProvider theme={theme}>
		<Provider store={store}>
			<GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
				<SnackbarProvider>
					<BrowserRouter>
						<App />
					</BrowserRouter>
				</SnackbarProvider>
			</GoogleReCaptchaProvider>
		</Provider>
	</MuiThemeProvider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
