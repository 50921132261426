import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { Typography, Grid } from '@material-ui/core';
import Item from '../../menuItem';

const useStyles = makeStyles((theme) => ({
	container: {
		height: '100%'
	},
	gridList: {
		overflow: 'auto',
		height: '53.5vh'
	}
}));

export default function CategoriesList({ categories, handleOnClick, title, handleOnEnter }) {
	const classes = useStyles();

	
	let items;
	if (categories) {
		items = categories.map((category) => (
			<Item handleOnClick={handleOnClick} option={category} key={category.id} handleOnEnter={handleOnEnter} />
		));
	} else return null;

	return (
		<Grid container>
			<Grid item xs={12} style={{marginLeft:20}} >
				<Typography color={'secondary'} variant={'h5'}>
					{title}
				</Typography>
			</Grid>
			<Grid item xs={12} className={classes.container}>
				<List className={classes.gridList}>{items}</List>
			</Grid>
		</Grid>
	);
}
