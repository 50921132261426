import { SERVER_BASE_URL } from '../constants';



export const LogInKiosk= async ( email, password, token ) => {
	try {
		const response = await fetch(`${SERVER_BASE_URL}logInKiosk`, {
			method: 'POST',
			body: JSON.stringify({ email, password, token }),
			headers: {
				'Content-Type': 'application/json',
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		} else {
			const json = await response.json();
			if (!json.token) {
				return false;
			}
			sessionStorage.setItem('tokenKiosk', json.token);
			return json.id;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
}


/**
 * Servicio para ingresar a la tienda
 * @param {String} email Correo electrónico
 * @param {String} password Contraseña
 * @param {String} confirmpassword Contraseña que debe coincidir con la anterior
 * @param { String } token token generado por recaptcha
 */
export const logIn = async (email, password, token) => {
	try {
		const response = await fetch(`${SERVER_BASE_URL}logIn`, {
			method: 'POST',
			body: JSON.stringify({ email, password, token }),
			headers: {
				'Content-Type': 'application/json',
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		} else {
			const json = await response.json();
			if (!json.token) {
				return false;
			}
			sessionStorage.setItem('token', json.token);
			return true;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const validateSession = async (token) => {
	try {
		const response = await fetch(`${SERVER_BASE_URL}validateSession`, {
			method: 'POST',
			body: JSON.stringify({ token }),
			headers: {
				'Content-Type': 'application/json',
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		}
		const json = await response.json();
		return json.validated;
	} catch (error) {
		console.log(error);
		return false;
	}
};

/**
 * Servicio para registrarse en la tienda
 * @param {String} email Correo electrónico
 * @param {String} password Contraseña
 * @param {String} confirmpassword Contraseña que debe coincidir con la anterior
 * @param { String } token token generado por recaptcha
 */
export const signIn = async (email, password, confirmpassword, token) => {
	try {
		const response = await fetch(`${SERVER_BASE_URL}signIn`, {
			method: 'POST',
			body: JSON.stringify({ email, password, confirmpassword, token }),
			headers: {
				'Content-Type': 'application/json',
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		}
		const json = await response.json();
		return json;
		
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const recoverPasswordEmail = async (email, token) => {
	try {
		const response = await fetch(`${SERVER_BASE_URL}recoverPasswordEmail`, {
			method: 'POST',
			body: JSON.stringify({ email, token }),
			headers: {
				'Content-Type': 'application/json',
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		} else {
			const json = await response.json();
			sessionStorage.setItem('token', json.token);
			return true;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const recoverPassword = async (password, confirmPassword, token) => {
	try {
		const response = await fetch(`${SERVER_BASE_URL}recoverPassword`, {
			method: 'POST',
			body: JSON.stringify({ password, confirmPassword }),
			headers: {
				'Content-Type': 'application/json',
				token: token,
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		} else {
			const json = await response.json();
			sessionStorage.setItem('token', json.token);
			return true;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
};
