import React, { PureComponent } from 'react';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ItemSidebar from '../../menuItem';
import { menuOpen } from '../../../../redux/actions';
import { connect } from 'react-redux';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { ListItemText, ListItemIcon, ListItem } from '@material-ui/core';
import { Link } from "react-router-dom";

class ListSidebar extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			showSubcategories: false,
			subcategories: []
		};
	}

	render() {
		const dispatch = this.props.menuOpen;

		const handleOnClickClose = e => dispatch(false);
		const handleOnClickShowSubcat = ({ idCategory }) => {

			const { subcategories } = this.props.categories.find(
				category => idCategory === category.idCategory
			);

			this.setState({ showSubcategories: true, subcategories });
		};
		const handleOnClickHideSubcat = e =>
			this.setState({ showSubcategories: false });
		const backwards = (
			<div>
				<ListItem button onClick={handleOnClickHideSubcat}>
					<ListItemIcon>
						<ArrowBackIosIcon />
					</ListItemIcon>
					<ListItemText primary={'Regresar'} />
				</ListItem>
				<Divider />
			</div>
		);

		const categories = this.props.categories.map(option => (
			<ItemSidebar
				option={option}
				key={option.title}
				handleOnClick={handleOnClickShowSubcat}
			/>
		));
		const subcategories = this.state.subcategories.map(option => (
			<Link to={`/product/${option.idCategory}/${option.idSubcategory}/0`}>
				<ItemSidebar
				option={option}
				key={option.title}
				handleOnClick={handleOnClickClose}
			/>
			</Link>	
		));
		return (
			<List component="nav" aria-label="">
				<div>{this.state.showSubcategories ? backwards : null}</div>
				{this.state.showSubcategories ? subcategories : categories}
			</List>
		);
	}
}

function mapToProps(state) {
	return { categories: state.categories };
}

export default connect(mapToProps, { menuOpen })(ListSidebar);
