const initialState = {
	isLogged: false,
	token: null,
};

const logged = (state = initialState, action) => {
	switch (action.type) {
		case 'SIGN_IN_KIOSK':
			const { token } = action.payload;

			return { isLogged: true, token: token};
		case 'LOG_OUT_KIOSK':
			return { ...initialState, isLogged: false };
		default:
			return state;
	}
};

export default logged;
