/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import LogIn from '../../miscellaneous/LogIn';
import SignIn from '../../miscellaneous/SignUp';
import PasswordForgotten from '../../miscellaneous/PasswordForgotten';

export default function LoginDialog({ handleClose }) {
	const open = useSelector(state => {
		return state.modal.loginOpen;
	});
	const [haveAnAcount, setHaveAnAcount] = useState(1);

	const handleUserAccount = () => setHaveAnAcount(1);
	const handleNoUserAccount = () => setHaveAnAcount(2);
	const handleForgottenPassword = () => setHaveAnAcount(3);
	const handleOnClose=() => {
		handleClose()
		setTimeout(() => setHaveAnAcount(1),200)
	}
	
	const showContent = () => {
		if (haveAnAcount === 1) {
			return (
				<LogIn
					handleNoUserAccount={handleNoUserAccount}
					handleForgottenPassword={handleForgottenPassword}
					handleOnClose={handleOnClose}
				/>
			);
		} else if (haveAnAcount === 2) {
			return (
				<SignIn
					handleForgottenPassword={handleForgottenPassword}
					handleUserAccount={handleUserAccount}
					handleOnClose={handleOnClose}
				/>
			);
		} else {
			return (
				<PasswordForgotten
					handleNoUserAccount={handleNoUserAccount}
					handleUserAccount={handleUserAccount}
					handleOnClose={handleOnClose}
				/>
			);
		}
	};

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleOnClose}
				aria-labelledby="form-dialog-title"
				fullWidth
			>
				<DialogContent>{showContent()}</DialogContent>
			</Dialog>
		</div>
	);
}
