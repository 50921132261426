import React from 'react'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/styles';
import { Grow } from '@material-ui/core';
import ProductCardContent from "./ProductCardContent";

const ProductCard = ({ card,handleClick }) => {
    const useStyles = makeStyles({
        root: {
            border: 0,
            boxShadow: '17px 9px 38px -24px rgba(0,0,0,0.11)',
            borderRadius: '10px',
            margin:15
        },
        media: {
            width: '100%',
            backgroundColor: 'white',
            //paddingTop: '24vh', 
        },
    });

    const classes = useStyles();


    return (
        <Grow in={true}>
            <Card className={classes.root} onClick={handleClick}>
                <img src={encodeURI(card.image)} alt='producto' className={classes.media} />
                <CardContent>
                    <ProductCardContent content={card} />
                </CardContent>
            </Card>
        </Grow>
    )
        
}

export default ProductCard