import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import logof from './face-white.png';
import logoi from './insta-white.png';
import logom from './mess-white.png';
import logow from './what-white.png';
import Copyright from '../miscellaneous/Copyright';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	main: {
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(2),
	},
	footer: {
		padding: theme.spacing(3, 2),
		marginTop: '5vh',
		minHeight: '32vh',
		background: 'linear-gradient(to right, #00b4db, #0083b0)',
		boxShadow: '5px 4px 18px -2px rgba(0,0,0,0.75)',
		color: '#FFFFFF',
	},
}));

export default function Footer() {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<footer className={classes.footer}>
				<Container>
					<Grid container spacing={3}>
						<Grid item xs={6} md={4}>
							<br></br>
							<Typography variant="h5" gutterBottom>
								Sucursales
							</Typography>
							<Typography variant="subtitle1">Centro</Typography>
							<Typography variant="body2">
								Aquiles Serdán No.521, Col. Centro C.P. 58000.
								Morelia, Mich.<br></br>Tel. (443) 3 13 96 94
								<br></br>Cel. 44 34 91 40 40
							</Typography>
						</Grid>
						<Grid item xs={6} md={4}>
							<br></br>
							<br></br>
							<br></br>
							<Typography variant="subtitle1">Madero</Typography>
							<Typography variant="body2">
								Av. Madero Ote. No. 652-A, Col. Centro C.P.
								58000. Morelia, Mich.<br></br>Tel: (443) 321 52
								32
							</Typography>
						</Grid>
						<Grid item xs={6} md={4}>
							<br></br>
							<Typography variant="h5" gutterBottom>
								Contactanos
							</Typography>
							<Typography align="left" variant="body2">
								<Link
									color="inherit"
									href="mailto:ventas@tecnicamara.com"
								>
									ventas@tecnicamara.com
								</Link>
							</Typography>
							<br></br>
							<Typography align="left" variant="body2">
								<Link
									color="inherit"
									href="https://wa.me/5214434914040"
									target="_blank"
								>
									<img
										alt="whatsApp"
										style={{ marginRight: '2vh' }}
										src={logow}
										width="25px"
									></img>
								</Link>
								<Link
									color="inherit"
									href="https://m.me/tecnicamara.mx"
									target="_blank"
								>
									<img
										alt="tecnicamara"
										style={{ marginRight: '2vh' }}
										src={logom}
										width="25px"
									></img>
								</Link>
								<Link
									color="inherit"
									href="https://www.facebook.com/tecnicamara.mx/"
									target="_blank"
								>
									<img
										alt="facebook"
										style={{ marginRight: '2vh' }}
										src={logof}
										width="25px"
									></img>
								</Link>
								<Link
									color="inherit"
									href="https://www.instagram.com/tecnicamara"
									target="_blank"
								>
									<img
										alt="instagram"
										style={{ marginRight: '2vh' }}
										src={logoi}
										width="25px"
									></img>
								</Link>
							</Typography>
						</Grid>
					</Grid>
					<Copyright />
					<br></br>
					<Grid container justify='flex-end'>
						<Grid item>
							<Typography variant="caption" align={'right'}>
								{
									'This site is protected by reCAPTCHA and the Google '
								}
								<a href="https://policies.google.com/privacy">
									{'Privacy Policy'}
								</a>
								{' and '}
								<a href="https://policies.google.com/terms">
									{' Terms of Service '}
								</a>
								{'apply'}
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</footer>
		</div>
	);
}
