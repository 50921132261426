import React from 'react';
import { LinearProgress, Grid } from '@material-ui/core';

export default function Loading({height='80vh'}) {
	return (
		<Grid
			container
			direction="row"
			style={{ height , width: '100%', alignItems: 'center' }}
			justify="center"
			alignItems="center"
		>
			<Grid item xs={6}>
				<LinearProgress />
			</Grid>
		</Grid>
	);
}
