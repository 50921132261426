import React,{Fragment}  from 'react'
import { SnackbarProvider as Provider} from 'notistack';
import { IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

export default function SnackbarProvider({children}) {
    const notistackRef = React.useRef(); // or React.createRef 
    // customized
    const action = (key) => (
        <Fragment>
            <IconButton style={{color:'white'}}
                onClick={() => {
                    notistackRef.current.closeSnackbar(key);
                }}
            >
                <CancelIcon/>
            </IconButton>
        </Fragment>
    );

    return (
        <Provider ref={notistackRef} action={action}>
            {children}
        </Provider>
    )
}
