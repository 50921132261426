const initialState = {
	isLogged: false,
	name: '',
	email: '',
	token:null,
};

const logged = (state = initialState, action) => {
	switch (action.type) {
		case 'SIGN_IN':
			return { ...state, isLogged: true, token: action.payload };
		case 'LOG_OUT':
			return { ...state, isLogged: false };
		default:
			return state;
	}
};

export default logged;
