import React from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import Copyright from "../Copyright";

export default function ValidatedSignUp({ handleOnClose }) {
	return (
		<div>
			<Grid container>
				<Grid item xs={12} style={{ marginBottom: 12 }}>
					<Typography variant="h6" align='center'>
						Gracias por registarte en tecnicamara
					</Typography>
					<Typography variant="body1" align='center'>
						Se ha enviado un correo para verificar tu cuenta, hasta
						que no esté verificada, no podrás realizar compras
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Button
						fullWidth
						variant="contained"
						color="primary"
						onClick={handleOnClose}
					>
						Cerrar
					</Button>
				</Grid>
                <Grid item xs={12} style={{marginTop:30}}>
                    <Copyright/>
                </Grid>
			</Grid>
		</div>
	);
}
