import { AppBar, Grid, IconButton, Toolbar, Hidden } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import Sidebar from './Sidebar';
import { menuOpen } from '../../redux/actions';
import PopoverMenu from './PopoverMenu';
import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import Search from './Search';
import NavIcons from './NavIcons';
import logo from './logoTecnicamara-w.png';

const useStyles = makeStyles((theme) => ({
	root: {
		top: 0,
		zIndex: 4,
		position: 'sticky',
		flexGrow: 1,
	},
	image: {
		width: '100%',
		maxWidth: 135,
		maxHeight: 50,
	},
	navBar: {
		...theme.palette.primary.nav,
		height: 60,
	},
	iconButton: {
		padding: 0,
	},
	centered: {
		padding: '0 !important',
	},
}));

export default function Navbar() {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const dispatch = useDispatch();
	const isOpen = useSelector((state) => state.menu.open, shallowEqual);

	//apartado de menú
	const toggleMenu = () => dispatch(menuOpen(!isOpen));
	const handleOnClickMenu = (e) => {
		toggleMenu();
		setAnchorEl(e.currentTarget);
	};

	const handlePopoverClose = () => {
		toggleMenu();
		setAnchorEl(null);
	};
	//fin de menú

	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Hidden smUp>
				<Sidebar />
			</Hidden>
			<Hidden xsDown>
				<PopoverMenu
					anchorEl={anchorEl}
					handlePopoverClose={handlePopoverClose}
				/>
			</Hidden>

			<AppBar position="static" className={classes.navBar}>
				<Toolbar>
					<Grid
						container
						direction="row"
						spacing={2}
						alignItems="center"
					>
						<Grid item xs={1}>
							<IconButton
								className={classes.iconButton}
								aria-label="menu"
								onClick={handleOnClickMenu}
							>
								<MenuIcon />
							</IconButton>
						</Grid>
						<Grid item xs={2} lg={2} className={classes.centered}>
							<Link to="/">
								<img
									src={logo}
									alt="logo"
									className={classes.image}
								/>
							</Link>
						</Grid>
						<Grid item xs={6} lg={7}>
							<Search />
						</Grid>
						{/* Botones del carrito y de perfil */}
						<NavIcons />
					</Grid>
				</Toolbar>
			</AppBar>
		</div>
	);
}
