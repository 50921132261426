import { createMuiTheme } from "@material-ui/core";


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#E77226',
            nav:{
                background: 'linear-gradient(193deg, rgba(181,78,132,1) 33%, rgba(255,128,8,1) 91%)'
            }
        },
        secondary: {
            main: '#527DDF'
        }
    }
})

export default theme