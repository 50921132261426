/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Popover, Grid, Typography, Divider, Button } from '@material-ui/core';
import {
	closeCart,
	setCartProduct,
	deleteCartProduct,
} from '../../../redux/actions';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import { Link, withRouter } from 'react-router-dom';
import PaymentRoundedIcon from '@material-ui/icons/PaymentRounded';
import ShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCartRounded';
import { deleteCart } from '../../../services/cart';
import CartProduct from './CartProduct';
import { withSnackbar } from 'notistack';
/**
 * obtener de redux la variable cart que contiene los elementos en el carrito
 * @param {*} state redux state
 */
function mapStateToProps(state) {
	return {
		promos: state.promos,
		products: state.cart.products,
		isOpen: state.cart.isOpen,
		isLogged: state.user.isLogged,
	};
}

class Cart extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			total: 0,
		};
	}

	getTotal() {
		let total = 0;
		for (const product of this.props.products) {
			const {
				price,
				quantityShop: quantity,
				idOffer,
				quantityOffer,
				priceWithDiscount,
			} = product;
			if (!idOffer) {
				total += price * quantity;
				continue;
			}
			if (quantity % quantityOffer === 0) {
				total += priceWithDiscount * quantity;
				continue;
			}
			total += price * quantity;
		}
		this.setState({ total });
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.total === this.state.total) {
			this.getTotal();
		}
		if (
			this.props.products.length === prevProps.products.length &&
			this.props.isLogged === prevProps.isLogged
		) {
			return;
		}
	}

	componentWillUnmount() {
		this.props.closeCart();
	}

	render() {
		const simpleStyle = {
			root: {
				position: 'fixed',
				top: 55,
				left: '1', //no sé cómo funciona... pero con este valor se acomoda :B
				width: 300,
			},
			products: {
				maxHeight: '55vh',
				minHeight: 200,
				overflow: 'auto',
			},
			button: {
				margin: 10,
				width: '90%',
			},
		};

		const handleOnContinue = () => {
			//subir
			window.scrollTo({
				left: 0,
				top: 0,
				behavior: 'smooth',
			});
			this.props.history.push('/');
			this.props.closeCart();
		};

		const handleOnClickPayment = () => {
			if (!this.props.products.length) {
				return this.props.enqueueSnackbar(
					'Aún no tienes productos para procesar la compra',
					{ variant: 'warning' }
				);
			}
			this.props.history.push('/checkout');
			this.props.closeCart();
		};

		const noProducts = (
			<div style={{ margin: 10 }}>
				<Typography variant="subtitle1" display="block" align="center">
					No tienes productos en tu carrito
				</Typography>
			</div>
		);

		const handleDeleteProduct = (idShop) => (e) => {
			e.preventDefault();
			e.stopPropagation();
			deleteCart(idShop).then((isCorrect) => {
				if (isCorrect) {
					this.props.deleteCartProduct(idShop);
				}
			});
		};

		const products = (
			<div>
				{this.props.products.map((product) => (
					<CartProduct
						product={product}
						handleOnDelete={handleDeleteProduct}
						onClick={this.props.closeCart}
						key={product.idShop}
					/>
				))}
			</div>
		);

		return (
			<Popover
				open={this.props.isOpen}
				anchorReference="none"
				onClose={this.props.closeCart}
				style={simpleStyle.root}
			>
				<Grid container direction="row">
					<Grid item xs={12} style={simpleStyle.products}>
						<Divider variant={'middle'} />
						{this.props.products.length ? products : noProducts}
					</Grid>
					<Grid item xs={12}>
						<Divider variant={'middle'} />
					</Grid>
					{/* subtotal */}
					<Grid item xs={6} style={{ paddingLeft: 15 }}>
						<Typography
							variant={'h6'}
							color={'primary'}
							align={'left'}
							display={'block'}
						>
							Subtotal:
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography
							variant={'h6'}
							color={'secondary'}
							align={'left'}
							display={'block'}
						>
							{`$ ${this.state.total.toFixed(2)}`}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Divider variant={'middle'} />
					</Grid>
					<Grid item xs={12}>
						<Link to={'/cart'}>
							<Button
								variant="contained"
								color="primary"
								style={simpleStyle.button}
								onClick={this.props.closeCart}
								startIcon={<ShoppingCartRoundedIcon />}
							>
								Carrito
							</Button>
						</Link>
					</Grid>
					<Grid item xs={12}>
						<Button
							variant="contained"
							color="secondary"
							style={simpleStyle.button}
							onClick={handleOnClickPayment}
							startIcon={<PaymentRoundedIcon />}
						>
							Proceder al pago
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Button
							variant="contained"
							color="secondary"
							style={simpleStyle.button}
							onClick={handleOnContinue}
							startIcon={<HomeRoundedIcon />}
						>
							Seguir comprando
						</Button>
					</Grid>
				</Grid>
			</Popover>
		);
	}
}

export default connect(mapStateToProps, {
	closeCart,
	setCartProduct,
	deleteCartProduct,
})(withRouter(withSnackbar(Cart)));
