import React, { Component } from 'react';
import { Divider, List, ListItem, Popper, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import { withRouter } from 'react-router-dom';
import { productsByName } from '../../services/products';
import OutsideHandler from "react-outside-click-handler";

class Search extends Component {
	constructor(props) {
		super(props);
		this.textInput = React.createRef();
		this.state = {
			search: '',
			needToSearch: false,
			result: [],
			isSearchOpen: false
		};
	}

	componentDidUpdate() {
		if (this.state.needToSearch) {
			// this.setState({ isLoading: true });
			productsByName(this.state.search).then(({ products }) => {
				this.setState({
					...this.state,
					needToSearch: false,
					result: products || []
				});
			});
		}
	}

	render() {
		const styles = {
			root: {
				width: '100%',
				display: 'flex',
				alignItems: 'center',
				height: '30px'
			},
			input: {
				paddingLeft:10,
				flex: 1
			},
			iconButton: {
				padding: 10
			},
			result: {
				width: 'calc(49% - 20px)',
				minWidth: 200,
				zIndex: 200
			}
		};
		const handleOnChange = e => {
			const value = e.target.value;
			this.setState({ ...this.state, search: value });
		};

		const handleSearch = e => {
			if (!(e.key === 'Enter' || !e.key)) {
				return;
			}
			if (this.state.search) {
				this.setState({
					...this.state,
					needToSearch: true,
					isSearchOpen: true
				});
			} else {
				this.setState({
					...this.state,
					needToSearch: false,
					result: [],
					isSearchOpen: true
				});
			}
		};

		const handleOnCloseSearch = () => {
			console.log('cerando');
			this.setState({ ...this.state, isSearchOpen: false });
		};

		const items = () => {
			const closeItemList = item => e => {
				this.setState({
					...this.state,
					search: '',
					result: [],
					isSearchOpen: false
				});
				const direction = `/product/${item.idCategory ||
					0}/${item.idSubcategory || 0}/${item.id || 0}`;
					
				this.props.history.push(direction);
			};
			return (
				<Popper
					open={this.state.isSearchOpen}
					anchorEl={this.textInput.current}
					onClose={handleOnCloseSearch}
					style={styles.result}
					placement="bottom-start"
					modifiers={{
						flip: {
							enabled: false
						},
						preventOverflow: {
							enabled: true,
							boundariesElement: 'undefined'
						},
						arrow: {
							enabled: true
						}
					}}
				>
					<OutsideHandler onOutsideClick={handleOnCloseSearch}>
					<Paper>
						<List>
							{this.state.result.map(item => (
								<div
									key={item.id}
									onClick={closeItemList(item)}
								>
									<ListItem key={item.id}>
										{item.name}
									</ListItem>
									<Divider light />
								</div>
							))}
						</List>
						{!this.state.result.length ? (
							<div>
								<Typography
									variant="h5"
									display="block"
									align="center"
								>
									No se han encontrado productos
								</Typography>
								<Typography
									variant="caption"
									display="block"
									align="center"
								>
									Presiona enter para empezar a buscar
								</Typography>
							</div>
						) : null}
					</Paper>
					</OutsideHandler>
				</Popper>
			);
		};
		return (
			<Paper style={styles.root}>
				<InputBase
					value={this.state.search}
					style={styles.input}
					ref={this.textInput}
					placeholder="Busca productos"
					onChange={handleOnChange}
					onKeyUp={handleSearch}
					onFocus={handleSearch}
				/>
				{items()}
				<IconButton
					style={styles.iconButton}
					aria-label="search"
					onClick={handleSearch}
				>
					<SearchIcon />
				</IconButton>
			</Paper>
		);
	}
}

export default withRouter(Search);
