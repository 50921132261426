import { SERVER_BASE_URL } from '../constants';
const categories = async function () {
	try {
		const response = await fetch(SERVER_BASE_URL + `categories`, {
			headers: {
				'Content-Type': 'application/json',
				token: sessionStorage.getItem('token'),
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		}
		const json = await response.json();
		let { categories } = json;
		return formatCategories(categories);
	} catch (error) {
		console.error(error);
		return [];
	}
};

export const subcategoriesByCategory = async (idCategory) => {
	try {
		const response = await fetch(
			`${SERVER_BASE_URL}subcategoriesByCategory`,
			{
				method: 'POST',
				body: JSON.stringify({ idCategory }),
				headers: {
					'Content-Type': 'application/json',
					token: sessionStorage.getItem('token'),
				},
			}
		);
		if (!response.ok) {
			throw Error(response.statusText);
		} else {
			const json = await response.json();

			return json.subcategorias.length ? json.subcategorias : [];
		}
	} catch (error) {
		console.log(error);
		return [];
	}
};

export const categoriesCarousel = async () => {
	try {
		const response = await fetch(`${SERVER_BASE_URL}categoriesCarousel`, {
			headers: {
				'Content-Type': 'application/json',
				token: sessionStorage.getItem('token'),
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		}
		const json = await response.json();

		return json.categories.length ? formatCategories(json.categories) : [];
	} catch (error) {
		console.log(error);
		return [];
	}
};

function formatCategories(categories) {
	categories = categories.map((category) => {
		category.id = category.idCategory;
		category.subcategories.map((subcategory) => {
			subcategory.id = `${subcategory.idSubcategory}${category.idCategory}`;
			subcategory.idCategory = category.idCategory;
			if (!subcategory.products) {
				return null;
			}
			subcategory.products.map((product) => {
				product.image = SERVER_BASE_URL + product.image;
				return product;
			});
			return subcategory;
		});
		return category;
	});

	return categories;
}

export default categories;
