import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { logInKiosk } from '../../redux/actions';
import { LogInKiosk as LogInKioskService } from '../../services/login';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSnackbar } from 'notistack';
import Copyright from '../miscellaneous/Copyright';
import { validateEmail } from '../../services/validations';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh',
	},
	image: {
		backgroundImage: 'url(https://source.unsplash.com/random)',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
	paper: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

export default function SignInSide() {
	const classes = useStyles();
	const [password, setPassword] = useState('');
	const [email, setEmail] = useState('');
	const [valid, setValid] = useState(true);
	const [remember, setRemember] = useState(true);
	const { enqueueSnackbar } = useSnackbar();
	const { executeRecaptcha } = useGoogleReCaptcha();
	const dispatch = useDispatch();

	const handleOnKeyEnter = (e) => {
		if (e.key === 'Enter') {
			handleOnSubmit();
		}
	};
	const handleOnChangeEmail = (e) => {
		setEmail(e.target.value);
	};
	const handleOnChangePassword = (e) => {
		setPassword(e.target.value);
	};

	const handleRememberMe = (e) => {
		setRemember(e.target.checked);
	};

	const handleOnSubmit = async () => {
		let validated = true;
		//validar campos
		if (!validateEmail(email)) {
			validated = false;
			enqueueSnackbar('Correo no válido', { variant: 'warning' });
		}

		if (!password) {
			validated = false;
			enqueueSnackbar('Favor de ingresar contraseña', {
				variant: 'warning',
			});
		}
		if (!validated) return;
		let token;
		try {
			token = await executeRecaptcha('login');
		} catch (error) {
			enqueueSnackbar('Pro favor deshabilite los bloqueadores', {
				variant: 'info',
			});
			return console.log(error);
		}
		const success = await LogInKioskService(email, password, token);
		if (!success) {
			enqueueSnackbar('Error al ingresar', { variant: 'error' });
			setValid(false);
			return;
		}
		dispatch(logInKiosk(token));
		sessionStorage.setItem('keepSession', remember);
	};
	return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />
			<Grid item xs={false} sm={4} md={7} className={classes.image} />
			<Grid
				item
				xs={12}
				sm={8}
				md={5}
				component={Paper}
				elevation={6}
				square
			>
				<div className={classes.paper}>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Login
					</Typography>
					<form className={classes.form}>
						<TextField
							value={email}
							variant="standard"
							margin="normal"
							required
							fullWidth
							id="email"
							label="Correlo electrónico"
							name="email"
							autoComplete="email"
							autoFocus
							onChange={handleOnChangeEmail}
						/>
						<TextField
							value={password}
							variant="standard"
							margin="normal"
							required
							fullWidth
							name="password"
							label="Contraseña"
							type="password"
							onKeyPress={handleOnKeyEnter}
							onChange={handleOnChangePassword}
							id="password"
							autoComplete="current-password"
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={remember}
									color="primary"
									onChange={handleRememberMe}
								/>
							}
							label="Recuerdame"
						/>
						{!valid ? (
							<Typography color="error">
								Favor de validar los campos
							</Typography>
						) : null}
						<Button
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
							onClick={handleOnSubmit}
						>
							Iniciar sesión
						</Button>
						<Grid container>
							<Grid item xs>
								<Link href="#" variant="body2">
									¿Olvidaste tu contraseña?
								</Link>
							</Grid>
							<Grid item>
								<Link href="#" variant="body2">
									{'No tienes una cuenta con nosotros?'}
								</Link>
							</Grid>
						</Grid>
						<Box mt={5}>
							<Copyright />
						</Box>
					</form>
				</div>
				<Typography variant="caption" align={'right'}>
					{'This site is protected by reCAPTCHA and the Google '}
					<a href="https://policies.google.com/privacy">
						{'Privacy Policy'}
					</a>
					{' and '}
					<a href="https://policies.google.com/terms">
						{' Terms of Service '}
					</a>
					{'apply'}
				</Typography>
			</Grid>
		</Grid>
	);
}
