export const SERVER_BASE_URL = 'https://tecnicamara.com/ws/public/';
export const SERVER_BASE_URL_SHOP = 'https://tecnicamara.com/ws/';
export const PUBLIC_FOLDER = process.env.PUBLIC_URL;
export const RECAPTCHA_KEY = '6LfqltIUAAAAAFvu-bKIxwpQKaCNeQmt8yGad5Dx';

/**
 * Rutas protegidas
 */
export const PROTECTED_ROUTES = ['/user*', '/checkout*'];


/**
 * Porcentaje de entrega según el status
 */
export const SHIPPING_STATUS_PERCENTAGE = {
	0: 0,
	1: 100,
	2: 50,
};

export const SHIPPING_STATUS = {
	0: 'Pedido',
	1: 'Creando',
	2: 'En camino',
	3: 'Entregado',
};

/**
 * Palabras reservadas de productos para su personalización
 */
export const RESERVED_WORDS = {
	PRODUCT_3D_MODEL: 'Modelo3D',
	PRODUCT_HEIGH: 'Alto',
	PRODUCT_WIDTH: 'Ancho',
	PRODUCT_PAGES: 'Hojas',
};

export const PAYMENT_TYPE={
    CARD:3,
    CASH:1,
}


export const CFDI_VALUES = [
	{ value: 'G01', text: 'G01 - Adquisición de mercancias' },
	{
		value: 'G02',
		text: 'G02 - Devoluciones, descuentos o bonificaciones',
	},
	{ value: 'G03', text: 'G03 - Gastos en general' },
	{ value: 'I01', text: 'I01 - Construcciones' },
	{
		value: 'I02',
		text: 'I02 - Mobilario y equipo de oficina por inversiones',
	},
	{ value: 'I03', text: 'I03 - Equipo de transporte' },
	{ value: 'I04', text: 'I04 - Equipo de computo y accesorios' },
	{
		value: 'I05',
		text: 'I05 - Dados, troqueles, moldes, matrices y herramental',
	},
	{ value: 'I06', text: 'I06 - Comunicaciones telefónicas' },
	{ value: 'I07', text: 'I07 - Comunicaciones satelitales' },
	{ value: 'I08', text: 'I08 - Otra maquinaria y equipo' },
	{
		value: 'D01',
		text: 'D01 - Honorarios médicos, dentales y gastos hospitalarios',
	},
	{
		value: 'D02',
		text: 'D02 - Gastos médicos por incapacidad o discapacidad',
	},
	{ value: 'D03', text: 'D03 - Gastos funerales' },
	{ value: 'D04', text: 'D04 - Donativos' },
	{
		value: 'D05',
		text:
			'D05 - Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)',
	},
	{ value: 'D06', text: 'D06 - Aportaciones voluntarias al SAR' },
	{ value: 'D07', text: 'D07 - Primas por seguros de gastos médicos' },
	{
		value: 'D08',
		text: 'D08 - Gastos de transportación escolar obligatoria',
	},
	{
		value: 'D09',
		text:
			'D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones',
	},
	{
		value: 'D10',
		text: 'D10 - Pagos por servicios educativos (colegiaturas)',
	},
	{ value: 'P01', text: 'P01 - Por definir' },
];

export const netPayExample = {
	amount: 5768.447999999999,
	promotion: 0,
	deviceFingerprintID: 1587951035705,
	card: {
		cardNumber: '4152333333333333',
		cardName: 'Alan Mauricio c',
		cardYear: '33',
		cardMonth: '02',
		cardCvv: '333',
		cardType: 'visa',
	},
	itemList: [
		{
			id: 1222,
			productSKU: 11,
			unitPrice: 2884.2239999999997,
			productName: 'test',
			quantity: 1,
			productCode: 1,
		},
		{
			id: 1222,
			productSKU: 11,
			unitPrice: 2884.2239999999997,
			productName: 'test',
			quantity: 1,
			productCode: 1,
		},
	],
	transactionId: 'dc5cc232-e427-4071-a3dd-8fa052ddc2a2',
	bill: {
		city: 'Morelia',
		country: 'MX',
		firstName: 'Alan Mauricio',
		lastName: 'Carrasco Pérez',
		email: 'alan@alan.com',
		phoneNumber: '4152313333',
		postalCode: '33333',
		state: 'Michoacán',
		street1: 'Fuerte de cóporo #143',
		street2: '',
		ipAddress: '177.240.152.237',
	},
	ship: {
		country: 'MX',
		street1: 'undefined, undefined, ',
		street2: '',
		shippingMethod: 'Express',
	},
};
